import React from "react";

const JourneyPageRendering = ({ onLoadIframe, page }) => {
  return (
    <iframe
      onLoad={onLoadIframe()}
      className="step-rendered-page" 
      src={page()} 
      title="Journey page" 
      width="100%" 
      height="100%"
      id="journey-iframe"
    />
  );
};

export default JourneyPageRendering;
