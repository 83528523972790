import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import JourneyRatingCard from "./JourneyRatingCard";
import { useHistory } from "react-router-dom";
import { getPathBySectionId } from "../services/utils"

const JourneyHeader = ({ data, index = 0, impact = false, parentContent = null, parentSection = null, title = null }) => {
  let showRatingCard = false;
  const history = useHistory();
  const goBack = () => {
    if(parentContent) {
      history.push(`/content/${parentContent}`)
    } else if(parentSection) {
      history.push(`${getPathBySectionId(parentSection)}`)
    } else {
      history.goBack();
    }
  };
  return (
    <div>
      <div className="journey-header-header">
        <div>
          <button className="back-to-journeys-button" onClick={goBack}>
            <div className="back-to-journeys-button-shadow"/>
            <BsArrowLeftShort size={30} color="grey" />
          </button>
        </div>
        <div>
          <label className="journey-title-label">{data[index]?.parentName || title || "Voltar" }</label>
        </div>
      </div>
      {showRatingCard && <JourneyRatingCard />}
    </div>
  );
};

export default JourneyHeader;
