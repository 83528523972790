import * as React from "react";
import "../index.css";
import {
  setLoginData,
  userLogin,
  sendEmailPassword,
  appDetails,
  renewlogin,
} from "../services/api";
import { Redirect, withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Loader from "../components/Loader";
import { BiError } from "react-icons/bi";
import { toast } from "react-toastify";
class Login extends React.Component {
  _isMounted = true;

  constructor(props) {
    super(props);

    this.state = {
      isDesktop: false,
      login: "",
      password: "",
      redirect: false,
      errorText: "",
      isLoading: false,
      forgotPassword: false,
      newAccess: false,
      isLoadingForgot: false,
      emailforgot: "",
      forgotMessage: "",
      appType: 1,
      appDetails: {},
      loadingApp: true,
      loadingAppError: false,
      loginSelected: false,
      passwordSelected: false,
      lastTracking: {},
      redirectLandingPage: false,
    };
    this.resize = this.resize.bind(this);
  }

  login(urlToken) {
    const loginSuccess = (res) => {
      if (res.data.success === false) {
        this.setState({
          errorText: "Senha ou usuário inválido",
          isLoading: false,
        });
        return;
      }
      setLoginData(res.data);
      this.setState({
        redirect: true,
        lastTracking: res.data.lastTracking[0] || {},
      });
    };

    const loginError = (err) => {
      this.setState({
        errorText: "Senha ou usuário inválido",
        isLoading: false,
      });
    };

    if (urlToken) {
      this.setState({ isLoading: true, errorText: "" });
      return renewlogin(urlToken)
        .then((res) => loginSuccess(res))
        .catch((err) => console.log(err));
    }

    this.setState({ isLoading: true, errorText: "" });
    userLogin(
      this.state.login,
      this.state.password,
      this.state.appDetails.idAppType
    )
      .then((res) => loginSuccess(res))
      .catch((err) => loginError(err));
  }

  _setPwaAppIcons(data) {
    let dynamicManifest = {
      name: data.name,
      short_name: data.name,
      description: data.name,
      start_url: ".",
      theme_color: "#000000",
      background_color: "#ffffff",
      display: "standalone",
      prefer_related_applications: false,
      icons: [
        {
          src: data.logo,
          sizes: "192x192",
          type: "image/png",
        },
        {
          src: data.logo,
          sizes: "256x256",
          type: "image/png",
        },
        {
          src: data.logo,
          sizes: "512x512",
          type: "image/png",
        },
      ],
    };
    const stringManifest = JSON.stringify(dynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#my-manifest-placeholder")
      .setAttribute("href", manifestURL);
  }

  async _appDetails() {
    this.setState({ loadingAppError: false });
    let favicon = document.getElementById("favicon");
    const bucketUrl = window.location.host;
    const staticUrl = "homolog.blast.etc.br";
    // smartapp.digital
    // homolog.bedigital.net.br
    // kryptosapp.com
    const currentUrl =
      process.env.NODE_ENV === "development" ? staticUrl : bucketUrl;
    if (this._isMounted) {
      try {
        const result = await appDetails(currentUrl);
        if (result.data.app.length == 0) {
          this.setState({ loadingAppError: true });
          return;
        }
        this._setPwaAppIcons(result.data.app[0]);
        localStorage.setItem("appDetails", JSON.stringify(result.data.app[0]));
        document.title = result.data.app[0].name;
        favicon.href = result.data.app[0].favIcon;
        this.setState({ appDetails: result.data.app[0] });
      } catch (err) {
        this.setState({ loadingAppError: true });
      } finally {
        this.setState({ loadingApp: false });
      }
    }
  }

  componentDidMount() {
    const url = window.location.href.split("?token=");
    const urlToken = url[1] || null;

    const isAutomaticLogin = () => {
      return urlToken && !this.state.login && !this.state.password;
    };

    this.resize();
    window.addEventListener("resize", this.resize);
    this._appDetails();
    isAutomaticLogin() && this.login(urlToken);
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.resize);
  }

  resize() {
    this.setState({ isDesktop: window.innerWidth > 900 });
  }

  sendMail() {
    this.setState({ isLoadingForgot: true });
    sendEmailPassword(this.state.emailforgot)
      .then((res) => {
        if (res.data.success == false) {
          toast.error("Erro ao enviar e-mail!", { position: "top-center" });
          this.setState({
            isLoadingForgot: false,
            forgotMessage: res.data.message,
          });
        }
        this.setState({
          isLoadingForgot: false,
          forgotMessage: res.data.message,
          forgotPassword: false,
        });
        toast.success("E-mail enviado com sucesso!", {
          position: "top-center",
        });
      })
      .catch((err) => {
        toast.error("Erro ao enviar e-mail!", { position: "top-center" });
        this.setState({
          isLoadingForgot: false,
          forgotMessage: "Erro ao enviar e-mail",
        });
      });
  }

  _getFormPosition() {
    const appDetails = this.state.appDetails;
    switch (appDetails?.loginPosition) {
      case "top":
        return "flex-start";
      case "middle":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return "center";
    }
  }

  isFromLandingPage() {
    const pathParams = new URLSearchParams(this.props.location.search);
    return !!pathParams.get("fromLP")
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              referrer: "login",
              lastTracking: this.state.lastTracking,
              lastContentRedirect:
                this.state.appDetails?.lastContentRedirect == 1 ||
                this.state.appDetails?.lastContentRedirect == "true",
            },
          }}
        />
      );
    }
    
    if (this.state.appDetails.landingPage == 1 && !this.isFromLandingPage()) {
      return (
        <Redirect to={{ pathname: "/landing-page" }} />
      );
    }

    const { isDesktop } = this.state;
    return (
      <>
        {this.state.loadingApp ? (
          <div
            className="tela-login-container"
            style={{ alignItems: "center" }}
          >
            <Loader
              customStyle={{ height: 100 }}
              message={"Carregando aplicação..."}
            />
          </div>
        ) : 
          <div className="login-main-container">
            <div className="login-form-wrapper">
              {this.state.forgotPassword ? (
                <div
                  className="tela-login-container"
                  style={{
                    alignItems: this._getFormPosition(),
                    backgroundImage: `url(${
                      !isDesktop
                        ? this.state.appDetails?.loginImageMobile
                          ? this.state.appDetails?.loginImageMobile
                          : this.state.appDetails?.loginImage
                        : "none"
                    })`,
                  }}
                >
                  <form
                    className="login-wrapper login-blur"
                    style={{ width: isDesktop ? "70%" : "80%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        marginBottom: 10,
                      }}
                    >
                      <h4>{this.state.newAccess ? 'Novo por aqui?' :  'Esqueceu sua senha?' }</h4>
                      <span style={{ fontWeight: 500 }}>
                        Por favor, insira seu e-mail para receber o link 
                        {this.state.newAccess  ? ' de primeiro acesso' : ' para alterar os seus dados'} 
                      </span>
                    </div>
                    <div>
                      <TextField
                        label="E-mail"
                        inputProps={{
                          autoComplete: "off",
                          autoCapitalize: "off",
                          autoCorrect: "off",
                          spellCheck: "off",
                        }}
                        style={{ width: "100%", marginBottom: 10 }}
                        onKeyDown={(e) => e.key == "Enter" && this.sendMail()}
                        onChange={(e) =>
                          this.setState({ emailforgot: e.target.value })
                        }
                      />
                    </div>
                    <br />
                    <div className="forgot-buttons">
                      <button
                        className="btn btn-secondary"
                        style={{
                          width: "40%",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          height: 45,
                          alignItems: "center",
                        }}
                        type="button"
                        onClick={() => this.setState({ forgotPassword: false, newAccess: false })}
                      >
                        <strong>VOLTAR</strong>
                      </button>
                      <button
                        className="btn botao-login"
                        style={{
                          width: "55%",
                          display: "flex",
                          justifyContent: "center",
                          position: "relative",
                          height: 45,
                          backgroundColor: this.state.appDetails.primaryColor,
                        }}
                        type="button"
                        onClick={() => {
                          this.sendMail();
                        }}
                      >
                        <strong>
                          {this.state.isLoadingForgot
                            ? "ENVIANDO"
                            : "ENVIAR E-MAIL"}
                        </strong>
                        {this.state.isLoadingForgot && (
                          <div
                            className="loader"
                            style={{ position: "static", marginLeft: 10 }}
                          />
                        )}
                      </button>
                    </div>
                    {!this.state.forgotPassword && (
                      <div className="login-error-container">
                        <span className="login-forgot">
                          {this.state.forgotMessage}
                        </span>
                      </div>
                    )}
                  </form>
                </div>
              ) : (
                <div
                  className="tela-login-container"
                  style={{
                    alignItems: this._getFormPosition(),
                    backgroundImage: `url(${
                      !isDesktop
                        ? this.state.appDetails?.loginImageMobile
                          ? this.state.appDetails?.loginImageMobile
                          : this.state.appDetails?.loginImage
                        : "none"
                    })`,
                  }}
                >
                  {this.state.loadingAppError ? (
                    <div className="tela-login-loading bucket-error">
                      <BiError size={50} color={"indianred"} />
                      <span>Nenhuma aplicação correspondente a URL</span>
                    </div>
                  ) : (
                    <div className="login-wrapper-container">
                      {
                        this.state.appDetails?.showLoginLogo !== 0 &&
                        <img
                          className="login-img-icon"
                          src={this.state.appDetails?.logo}
                          alt="login-logo"
                        />
                      }
                      <form className="login-wrapper">
                        <div>
                          <TextField
                            id="standard-basic"
                            label="Login"
                            autoFocus={true}
                            style={{ width: "100%", marginBottom: 10 }}
                            required
                            onBlur={() =>
                              this.setState({ loginSelected: false })
                            }
                            onSelect={(e) =>
                              e.type === "select" &&
                              this.setState({ loginSelected: true })
                            }
                            onChange={(e) =>
                              this.setState({ login: e.target.value })
                            }
                            InputLabelProps={{
                              style: {
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "100%",
                                color: "#804e6c",
                                fontFamily: "'Open Sans', sans-serif",
                                height: 25,
                                fontWeight: 18,
                                top: -5,
                              },
                            }}
                            inputProps={{
                              style: {
                                WebkitBoxShadow: `0 0 0 1000px ${
                                  this.state.loginSelected || this.state.login
                                    ? this.state.appDetails.backgroundColor ||
                                      "white"
                                    : "transparent"
                                } inset`,
                                // color: this.state.appDetails.loginTextColor,
                              },
                              autoComplete: "off",
                              autoCapitalize: "off",
                              autoCorrect: "off",
                              spellCheck: "off",
                            }}
                          />
                          <TextField
                            id="standard-basic"
                            type="password"
                            style={{ width: "100%", marginTop: 10 }}
                            label="Senha"
                            required
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                            onKeyDown={(e) => e.key == "Enter" && this.login()}
                            onBlur={() =>
                              this.setState({ passwordSelected: false })
                            }
                            onSelect={(e) =>
                              e.type === "select" &&
                              this.setState({ passwordSelected: true })
                            }
                            InputLabelProps={{
                              style: {
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "100%",
                                color: "#804e6c",
                                fontFamily: "'Open Sans', sans-serif",
                                height: 25,
                                fontWeight: 18,
                                top: -5,
                              },
                            }}
                            inputProps={{
                              style: {
                                WebkitBoxShadow: `0 0 0 1000px ${
                                  this.state.passwordSelected ||
                                  this.state.login
                                    ? this.state.appDetails.backgroundColor ||
                                      "white"
                                    : "transparent"
                                } inset`,
                                // color: this.state.appDetails.loginTextColor,
                              },
                              autoComplete: "off",
                            }}
                          />
                        </div>
                        <br />
                        <button
                          className="btn botao-login"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            backgroundColor:
                              this.state.appDetails?.primaryColor,
                            borderColor: this.state.appDetails?.secondaryColor,
                          }}
                          type="button"
                          onClick={() => {
                            this.login();
                          }}
                        >
                          <strong>Fazer login</strong>
                          {this.state.isLoading && (
                            <div style={{ marginLeft: 10 }}>
                              <div
                                className="loader"
                                style={{ position: "relative", right: 0 }}
                              />
                            </div>
                          )}
                        </button>
                        {this.state.forgotPassword && (
                          <div className="login-forgot-container">
                            <span
                              onClick={() =>
                                this.setState({ forgotPassword: true , newAccess: false})
                              }
                              className="login-forgot"
                              style={{ cursor: "pointer" }}
                            >
                              Esqueci minha senha
                            </span>
                          </div>
                        )}
                        <div className="login-forgot-container card-footer-login-options">
                          <span
                            className="login-forgot"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ forgotPassword: true , newAccess: false})
                            }
                          >
                            Esqueceu sua senha?
                          </span>
                          <span
                            className="login-forgot"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ forgotPassword: true, newAccess: true })
                            }
                          >
                            Primeiro acesso?
                          </span>
                        </div>
                        {this.state.errorText && (
                          <div
                            className="login-error-container"
                            style={{ marginTop: 10 }}
                          >
                            <span className="login-error-text">
                              {" "}
                              {this.state.errorText}{" "}
                            </span>
                          </div>
                        )}
                      </form>
                    </div>
                  )}
                </div>
              )
              }
            </div>
            <div className="login-image-wrapper">
              <div
                className="tela-login-container"
                style={{
                  backgroundImage: `url(${
                    !isDesktop && this.state.appDetails?.loginImageMobile
                      ? this.state.appDetails?.loginImageMobile
                      : this.state.appDetails?.loginImage
                  })`,
                  alignItems: this._getFormPosition(),
                }}
              />
            </div>
          </div>
        }
      </>
    );
  }
}

export default withRouter(Login);
