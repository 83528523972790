import React, { useState, useEffect, useRef } from "react";
import JourneyHeader from "./JourneyHeader";
import JourneyPageRendering from "./JourneyPageRendering";
import { BsChevronLeft, BsChevronRight, BsGeoAltFill } from "react-icons/bs";
import ImpactFullMenu from "./ImpactFullMenu";
import { getImpactMenu } from "../services/api";

const ImpactJourneyStepNavigation = (props) => {
  const [displaySideMenuState, setDisplaySideMenu] = useState(false);
  const [pages, setPages] = useState([]);
  const [index, setIndex] = useState(0)
  const [parentName, setParentName] = useState("");
  const [pageList, setPageList] = useState([])
  const fullMenuRef = useRef();
  const [lastPageNotAnchor, setLastPageNotAnchor] = useState(null)
  const [shouldScrollOnload, setShouldScrollOnload] = useState(false)
  const [currentClassName, setCurrentClassName] = useState(null)
  const [currentClassIndex, setCurrentClassIndex] = useState(null)

  const tryOnloadScroll = () => {
    if(shouldScrollOnload) {
      setShouldScrollOnload(false)
      setTimeout(() => {
        scrollToAnchor(currentClassName, currentClassIndex)
      }, 300);
    }
  }

  const scrollToAnchor = (className, index, anchorPage = {}) => {
    const journeyIframe = document.getElementById('journey-iframe')
    const anchors = journeyIframe.contentWindow.document.getElementsByClassName(className)
    if(anchors.length === 0) {
      // go to first page to the left without referencLink
      const anchorPageIndex = pageList.findIndex(b => b.idContentItem === anchorPage?.idContentItem)
      let firstPageWithoutAnchorIndex
      for(let i = anchorPageIndex; i >= 0; i--) {
        if (!pageList[i].referenceLink) {
          firstPageWithoutAnchorIndex = i
          i = 0
        }
      }

      if(firstPageWithoutAnchorIndex === 0 || firstPageWithoutAnchorIndex) {
        setShouldScrollOnload(true)
        setIndex(firstPageWithoutAnchorIndex) // move to first page to the left without anchor (referenceLink)
      }
    } else {
      anchors[index].scrollIntoView()
    }
  }

  const changePageViaMenu = (idContentItem) => {
    let anchorPages = []
    
    //find classes index on page json
    pageList.map(page => {
      if (page.referenceLink) {
        anchorPages.push({ ...page })
      }
    })

    pageList.map((page, index) => {
      if (page.idContentItem == idContentItem) {
        let scrolled = false
        // find wich anchor should scroll
        anchorPages.forEach((anchorPage, anchorIndex) => {
          if (anchorPage.idContentItem === pageList[index].idContentItem) {
            setCurrentClassName(anchorPage.referenceLink)
            setCurrentClassIndex(anchorIndex)
            setIndex(index);
            scrollToAnchor(anchorPage.referenceLink, anchorIndex, anchorPage)
            scrolled = true
          }
        })

        if (!scrolled) {
          setLastPageNotAnchor(null)
          setIndex(index);
        }
      }
    });
  };

  const changePage = (newIndex, oldIndex) => {
    let anchorPages = []
    if (newIndex >= 0 && newIndex < pageList.length) {
      // find classes index on page json
      pageList.map(page => {
        if (page.referenceLink) {
          anchorPages.push({ ...page })
        }
    })

    const isCurrentPageAnchor = pageList[newIndex]?.referenceLink ? true : false

    if(isCurrentPageAnchor) {
      const anchorPageIndex = pageList.findIndex(b => b.idContentItem === pageList[newIndex]?.idContentItem)
      let firstPageWithoutAnchor
      for(let i = anchorPageIndex; i >= 0; i--) {
        if (!pageList[i].referenceLink) {
          firstPageWithoutAnchor = pageList[i]
          i = 0
        }
      }
      if(firstPageWithoutAnchor) {
        setLastPageNotAnchor(firstPageWithoutAnchor)
      }
    }
    
      const newPage = pageList.find(item => item.idContentItem === pageList[newIndex].idContentItem)
      changePageViaMenu(newPage?.idContentItem)
    }
  };

  const selectPage = () => {
    if(lastPageNotAnchor) {
      return lastPageNotAnchor.contentLocation
    } else {
      return pageList[index]?.contentLocation
    }
  }

  useEffect(() => {
    if (pages.length > 0) {
      let newPageList = []
      pages.map((page) => {
        newPageList.push(...page?.children || []);
      });
      setPageList([...newPageList])
    }
  }, [pages, setPageList])


  useEffect(() => {
    getImpactMenu(props.data.parent)
      .then((res) => {
        if (!res.data.success) {
          return;
        }
        let array = [];
        setParentName(res.data?.result[0].text);
        array = res.data.result[0].children.map((page) => {
          return page;
        });
        setPages(array);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="step-main-container">
      <JourneyHeader parentSection={props.idSection} data={pages} title={pageList[index]?.text} index={index} />
      <div className="step-progress-bar">
        <div
          className="step-progress"
          style={{
            width: ((index + 1) / pageList.length) * 100 + "%",
          }}
        ></div>
      </div>
      <ImpactFullMenu
        ref={fullMenuRef}
        display={displaySideMenuState}
        parentName={parentName}
        pageList={pageList}
        pages={pages}
        pageIndex={index}
        idSection={props.idSection}
        pageClickHandler={(idContentItem) => {
          changePageViaMenu(idContentItem);
          setDisplaySideMenu(false);
        }}
        closeButtonHandler={() => {
          setDisplaySideMenu(false);
        }}
      />
      {pages.length > 0 && (
        <JourneyPageRendering
          onLoadIframe={tryOnloadScroll}
          page={selectPage} 
        />
      )}
      <div className="step-footer">
        <button
          className="step-footer-button"
          onClick={() => changePage(index - 1, index)}
        >
          <BsChevronLeft size={20} className="" />
        </button>
        <button
          className="step-footer-button"
          onClick={() => setDisplaySideMenu(!displaySideMenuState)}
        >
          <BsGeoAltFill size={23} className="" />
        </button>
        <button
          className="step-footer-button"
          onClick={() => changePage(index + 1, index)}
        >
          <BsChevronRight size={20} className="" />
        </button>
      </div>
    </div>
  );
};

export default ImpactJourneyStepNavigation;
