import React, { useEffect, useState } from "react";
import { getListContentChild } from "../services/api";
import { Link, useHistory } from "react-router-dom";
import { getDesignById, getFooterSize } from "../services/utils";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import JourneyHeader from "./JourneyHeader";

const MosaicJourneyStepList = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [showFooter, setShowFooter] = useState(null)
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)
  const history = useHistory()

  const _getDesign = (item, only = false, valueOnly = false) => {
    let design = getDesignById(props.idSection);

    if (!design[item]) {
      return null;
    } // return null if theres no item specified

    if (only) {
      // parameter only means it will only return the property specified
      if (valueOnly) {
        return design[item][only];
      } // parameter valueOnly means it will only return the property value if specified

      design = { [only]: design[item][only] };
      return design;
    }

    return design[item];
  }

  useEffect(() => {
    _getContent(props.data.idContentItem);
    setShowFooter(_getDesign(props.data.idSection, "general")?.showBottomMenu || "true")

    window.addEventListener("resize", () =>
      setIsDesktop(window.innerWidth > 768 )
    );
    
    const { updateHeaderStatus, updateFooterStatus } = props;
    updateFooterStatus(_getDesign("general")?.showBottomMenu || "true");
    updateHeaderStatus(_getDesign("general")?.showTopBar || "true");

  }, [])

  const _getContent = (id) => {
    setIsLoading(true)
    getListContentChild(id)
      .then((res) => {
        if (res.data.success == false) {
          setIsLoading(false)
          return;
        }
        const firstChapter = res.data?.listJourneyPart[0] || {}
        history.push(`/content/${firstChapter?.idContentItem}?idSection=${props.data.idSection}`)
        setIsLoading(false)
        setData(res.data.listJourneyPart)
      })
      .catch((err) => {
        setIsLoading(false)
      });
  }

  const getStylesItem = (items, indexItem) => {
    if (items === 2) {
      return isDesktop
        ? {
            width: "50%",
            minWidth: "50%",
            height: `calc(100vh - 54px - 51px ${showFooter && "- 5rem"})`,
          }
        : {
            width: "100%",
            minWidth: "100%",
            height: `calc(50vh - 27px - 51px ${showFooter && "- 2.5rem"})`,
          };
    }
    if (items > 4) {
      if (indexItem === 0) {
        return { minWidth: "66.66%" };
      }
    }
  }
    return (
      <section>
        <JourneyHeader parentSection={props?.data?.idSection} data={data}/>
        <div
          className="container-mosaic-journey"
          style={{ ..._getDesign("general"), ...getFooterSize() }}
        >
          {isLoading ? (
            <div className="loader loader-center loader-big"></div>
          ) : (
            data.map((item, index) => {
              return (
                <Link
                  to={`/content/${item.idContentItem}?idSection=${props.data.idSection}`}
                  className="journey-tile"
                  key={index}
                  style={{
                    height: isDesktop ? "55vh" : "35vh",
                    ...getStylesItem(data.length, index),
                  }}
                >
                  <div className="gradient-support" />
                  <div className="mosaic-text">
                    <div style={_getDesign("itemText")}>
                      <span> {item.text} </span>
                    </div>
                    {/* <div style={_getDesign("itemDescription")}>
                      <span> {item.description} </span>
                    </div> */}
                  </div>
                  <img
                    src={item.image}
                    style={{
                      minWidth: 165,
                      minHeight: 200,
                      backgroundColor: "lightgray",
                    }}
                    alt=""
                  />
                </Link>
              );
            })
          )}
        </div>
      </section>
    );
  
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(MosaicJourneyStepList);
